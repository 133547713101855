import "@selectize/selectize";

if (document.querySelectorAll('[data-component="selectize"]').length > 0) {
  document.addEventListener("DOMContentLoaded", function () {
    $('[data-toggle="select-origin"]').selectize();

    $('[data-toggle="select-destination"]').selectize();

    $('[data-toggle="select-outlet"]').selectize();

    $('[data-toggle="select-outlet-result"]').selectize();

    $('[data-toggle="select-book-city"]').selectize({
      placeholder: "Kota",
    });
  });
}
