import Modal from "bootstrap/js/dist/modal";

if (document.querySelectorAll('[data-component="modal"]').length > 0) {
    document.addEventListener('DOMContentLoaded', (event) => {

        var within_first_modal = false;

        $('.modal-captcha-trigger').on('click', function () {
            if ($(this).hasClass('within-first-modal')) {
                within_first_modal = true;

                $('#modal-login').modal('hide');
            }

            $('#modal-captcha').modal('show');
        });

        $('.modal-captcha-closed').on('click', function () {
            $('#modal-captcha').modal('hide');

            if (within_first_modal) {
                $('#modal-login').modal('show');

                within_first_modal = false;
            }
        })

        $('.modal-confirm-trigger').on('click', function () {
            if ($(this).hasClass('within-first-modal')) {
                within_first_modal = true;

                $('#modal-outlet').modal('hide');
            }

            $('#modal-confirmOutlet').modal('show');
        });

        $('.modal-confirm-closed').on('click', function () {
            $('#modal-confirmOutlet').modal('hide');

            if (within_first_modal) {
                $('#modal-outlet').modal('show');

                within_first_modal = false;
            }
        })

        // const items = [{
        //     className: '#modal-login',
        //     idName: 'modal-login'  
        // }, {
        //     className: '#modal-address',
        //     idName: 'modal-address'  
        // }, {
        //     className: '#modal-account',
        //     idName: 'modal-account'  
        // }, {
        //     className: '#modal-example',
        //     idName: 'modal-example'  
        // }]

        // for (let i = 0; i < items.length; i++) {
        //     const element = items[i];
        //     const elTrigger = document.querySelector(element.className);
            
        //     if (elTrigger) {
        //         Array.from(elTrigger)
        //             .forEach(ModalNode => new Modal(ModalNode))
        //     }
        // }
    })
}