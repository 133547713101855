/* @Risgie
 * 1. Copy File Custom.js ke js/interactive
 * 2. Pada file js/vendor.js, tambahkan import import "./interactive/Custom";
 * 3. Matikan/Comment pada import './interactive/Modal';
 * 4. Buka file js/main.js, matikan/comment pada import './interactive/AskClient';
 *
 */
import Modal from "bootstrap/js/dist/modal";

// $(function () {
//   $('a[data-bs-toggle="modal"]').click(function () {
//     var targetModal = $(this).attr("href");
//     $(targetModal + " form")[0].reset();
//     $(targetModal + " .alert-danger").remove();
//     $(targetModal).modal("show");
//   });
// });

window.CONFIG = (function () {
  const conf_info = {
    base_url: "/",
  };
  return {
    getValue: function (param) {
      return conf_info[param];
    },
  };
})();
$("a[href='#modal-login']").on("click", function (e) {
  e.preventDefault();
  var $modal_login = $("#modal-login");
  $modal_login.modal("show");
  $modal_login.find(".invalid-feedback").remove();
  $modal_login.find(".alert-danger").remove();
  $modal_login.find("[data-form='login-form-modal']")[0].reset();
  $modal_login.find(".btn").removeClass("btn-primary").addClass("btn-dark");
});
/**
 * Homepage
 */
if (
  document.querySelectorAll('[data-component="check-status-home"]').length > 0
) {
  document.addEventListener("DOMContentLoaded", function () {
    $(function () {
      var validationMessages = {
        required: $("#error_field_required").attr("data-field_required"),
        number: $("#error_field_numeric").attr("data-field_numeric"),
      };
      var check_status_home = $("[data-form='check-status-home']");
      var check_price_home = $("[data-form='check-price-home']");
      var check_outlet_home = $("[data-form='check-outlet-home']");
      var outlet = $("input[name='outlet']");
      var input_awb = $("input[name='waybillNo']");
      check_outlet_home.find(outlet).on("input", function (e) {
        e.preventDefault();
        checkStatus($(this));
      });
      check_outlet_home.on(
        "click",
        "[data-btn='check-outlet-home']",
        function (e) {
          e.preventDefault();
          var fieldName = outlet.attr("name");
          var errorMessage = "";
          if (outlet.val() === "") {
            errorMessage = transValidation("required");
          }
          if (errorMessage !== "") {
            var $form = $(this).closest(check_outlet_home);
            $form.find(".selectize-input.items.not-full").addClass("invalid");
            outlet.next(".error-message").text(errorMessage);
            return false;
          } else {
            hideError(outlet);
            check_outlet_home.trigger("submit");
          }
        }
      );
      check_status_home.find(input_awb).on("input change", function (e) {
        e.preventDefault();
        checkStatus($(this));
      });

      check_status_home.on(
        "click",
        "[data-btn='check-status-home']",
        function (e) {
          e.preventDefault();
          var fieldName = input_awb.attr("name");
          var errorMessage = "";
          if (input_awb.val() === "") {
            errorMessage = transValidation("required");
          }
          if (errorMessage !== "") {
            showError(input_awb, errorMessage);
            return false;
          } else {
            hideError(input_awb);
            check_status_home.trigger("submit");
          }
        }
      );
      check_price_home.find("input, select").on("input change", function (e) {
        e.preventDefault();
        var selectizeInput = $(".form-custom-selectize .selectize-input");
        var field = $(this);
        var fieldName = field.attr("name");
        var errorMessage = "";
        switch (fieldName) {
          case "origin":
            errorMessage =
              field.val() === "" ? transValidation("required") : "";
            break;
          case "destination":
            errorMessage =
              field.val() === "" ? transValidation("required") : "";
            break;
          case "weight":
            errorMessage =
              isNaN(field.val()) || field.val() === ""
                ? transValidation("number")
                : "";
            break;
        }

        if (errorMessage !== "") {
          var $form = $(this).closest(check_price_home);
          $form.find(".selectize-input.items.not-full").addClass("invalid");

          showError(field, errorMessage);
        } else {
          hideError(field);
        }
      });
      check_price_home.on(
        "click",
        "[data-btn='check-price-home']",
        function (e) {
          e.preventDefault();
          var hasError = false;
          var errorMessage = "";
          check_price_home.find("input").each(function () {
            var field = $(this);
            var fieldName = field.attr("name");
            if (typeof $(this).attr("name") != "undefined") {
              switch (fieldName) {
                case "origin":
                case "destination":
                  errorMessage =
                    field.val() === "" ? transValidation("required") : "";
                  break;
                case "weight":
                  errorMessage =
                    isNaN(field.val()) || field.val() === ""
                      ? transValidation("required")
                      : "";
                  break;
              }
            }
            if (errorMessage !== "") {
              var $form = $(this).closest(check_price_home);
              $form.find(".selectize-input.items.not-full").addClass("invalid");

              showError(field, errorMessage);
              hasError = true;
            } else {
              hideError(field);
            }
          });
          if (!hasError) {
            check_price_home.submit();
          } else {
            console.log(
              "Form has errors. Please correct them before submitting."
            );
          }
        }
      );

      function checkStatus(field) {
        var fieldName = field.attr("name");
        var errorMessage = "";
        if (field.val() === "") {
          errorMessage = transValidation("required");
        }
        if (errorMessage !== "") {
          showError(field, errorMessage);
        } else {
          hideError(field);
        }
      }

      function showError(field, message) {
        field.addClass("invalid");
        field.next(".error-message").text(message);
      }

      function hideError(field) {
        field.removeClass("invalid");
        field.next(".error-message").text("");
      }

      function transValidation(key) {
        return validationMessages[key];
      }

      $('[data-toggle="select-destination"]').selectize({
        valueField: "url",
        labelField: "name",
        searchField: "name",
        options: [],
        create: false,
        render: {
          option: function (item, escape) {
            return '<option value="' + item.key + '">' + item.key + "</option>";
          },
        },
        score: function (search) {
          var score = this.getScoreFunction(search);
          return function (item) {
            return score(item) * (1 + Math.min(item.watchers / 100, 1));
          };
        },
        load: function (query, callback) {
          if (!query.length) return callback();
          $.ajax({
            url: "/data-areas/" + encodeURIComponent(query),
            type: "GET",
            error: function () {
              callback();
            },
            success: function (res) {
              callback(res.repositories.slice(0, 10));
            },
          });
        },
      });
      $("#form_pricing_checked").on("submit", function () {
        let origin = $.trim($("#select-origin").val()).length;
        let destination = $.trim($("#select-destination").val()).length;
        if (origin === 0) {
          $("#select-origin").prop("required", true);
          return false;
        }
        if (destination === 0) {
          $("#select-destination").prop("required", true);
          return false;
        }
      });
      $("#form_outlet_checked").on("submit", function () {
        if ($.trim($("#select-outlet").val()).length === 0) {
          $("#select-outlet").prop("required", true);
          return false;
        }
      });
    });
  });
}
if (document.querySelectorAll('[data-component="modal"]').length > 0) {
  document.addEventListener("DOMContentLoaded", (event) => {
    var within_first_modal = false;

    $(".modal-captcha-trigger").on("click", function () {
      if ($(this).hasClass("within-first-modal")) {
        within_first_modal = true;

        $("#modal-login").modal("hide");
      }

      $("#modal-captcha").modal("show");
    });

    $(".modal-captcha-closed").on("click", function () {
      $("#modal-captcha").modal("hide");

      if (within_first_modal) {
        $("#modal-login").modal("show");

        within_first_modal = false;
      }
    });

    $(".modal-confirm-trigger").on("click", function () {
      if ($(this).hasClass("within-first-modal")) {
        within_first_modal = true;

        $("#modal-outlet").modal("hide");
      }

      $("#modal-confirmOutlet").modal("show");
    });

    $(".modal-confirm-closed").on("click", function () {
      $("#modal-confirmOutlet").modal("hide");

      if (within_first_modal) {
        $("#modal-outlet").modal("show");

        within_first_modal = false;
      }
    });
  });
}

if (document.querySelectorAll('[data-component="quiz-client"]').length > 0) {
  document.addEventListener("DOMContentLoaded", function () {
    let onCheckedElementOne = $(".question-one input");
    let onCheckedElementTwo = $(".question-two input");
    let nextElementOne = $(".question-two");

    // Sembunyikan elemen terkait dengan checkbox kedua
    nextElementOne.hide();

    function checkedElementPengiriman(checked) {
      if (checked) {
        onCheckedElementTwo.prop("checked", false);
        nextElementOne.delay(150).fadeIn();
      } else {
        nextElementOne.hide();
      }
    }

    // Event listener untuk checkbox pertama
    onCheckedElementOne.on("change", function () {
      onCheckedElementTwo.prop("checked", false);
      checkedElementPengiriman(this.checked);
    });

    // Event listener untuk checkbox kedua
    onCheckedElementTwo.on("change", function () {
      onCheckedElementOne.prop("checked", false);
      location.href = this.value;
    });
  });
}
if (
  document.querySelectorAll('[data-component="form-create-order"]').length > 0
) {
  document.addEventListener("DOMContentLoaded", function () {
    $("input[name='sender_add_contact']").on("change", function () {
      if ($(this).is(":checked")) {
        $(this).attr("value", true);
      } else {
        $(this).attr("value", false);
      }
    });
    $("input[name='recipient_add_contact']").on("click", function () {
      if ($(this).is(":checked")) {
        $(this).attr("value", true);
      } else {
        $(this).attr("value", false);
      }
    });
  });
}
/**
 * Global Selectize
 * .form-custom-selectize => dimasukan diform-nya kemudian masukan ke :
 *  Jika input => [data-toggle='selectize-area-input']
 *  Jika Select => [data-toggle='selectize-area-select']
 */
/**
 * MATIIN DULU SEMENTARA
 */
// if (document.querySelectorAll(".form-custom-selectize").length > 0) {
//   document.addEventListener("DOMContentLoaded", function () {
//     // Inisialisasi Selectize.js ketika input mendapatkan fokus
//     $(document).on("focus", ".form-custom-selectize", function () {
//       var $select = $(this);

//       // Memeriksa apakah Selectize sudah diinisialisasi sebelumnya
//       if (!$select.hasClass("selectized")) {
//         // Inisialisasi Selectize.js
//         $select.selectize({
//           valueField: "key",
//           labelField: "key",
//           searchField: "key",
//           preload: true,
//           maxItems: 1,
//           create: false,
//           render: {
//             item: function (item, escape) {
//               return "<div>" + escape(item.key) + "</div>";
//             },
//           },
//           load: function (query, callback) {
//            if (!query.length) return callback();
//             // Mengambil data melalui AJAX jQuery
//             $.ajax({
//               url: "/data-areas",
//               type: "GET",
//               dataType: "json",
//               data: {
//                 q: query,
//               },
//               success: function (response) {
//                 if (response.length > 0) {
//                   callback(response);
//                 }
//                 // console.log(response);
//               },
//               error: function (error) {
//                 console.error("Kesalahan pengambilan data:", error);
//               },
//             });
//           },
//         });
//       }
//     });
//   });
// }
if (document.querySelectorAll(".form-custom-selectize").length > 0) {
  document.addEventListener("DOMContentLoaded", function () {
    // Inisialisasi Selectize.js
    var selectizeInput = $("[data-toggle='selectize-area-input']").selectize({
      valueField: "key",
      labelField: "key",
      searchField: "key",
      preload: true,
      maxItems: 1, // if you want multiple select remove maxItem
      // options: [],
      create: false,
      render: {
        item: function (item, escape) {
          return "<div>" + escape(item.key) + "</div>";
        },
      },
      load: function (query, callback) {
        if (!query.length) return callback();
        $.ajax({
          url: "/data-areas",
          type: "GET",
          dataType: "json",
          data: {
            q: query,
          },
          success: function (response) {
            if (response.length > 0) {
              callback(response);
            }
          },
          error: function (error) {
            console.error("Kesalahan pengambilan data:", error);
          },
        });
      },
    });
    /**
     * get all area location
     */
    var selectizeSelect = $("[data-toggle='selectize-area-select']").selectize({
      valueField: "key",
      labelField: "key",
      searchField: "key",
      preload: true,
      // options: [],
      create: false,
      render: {
        option: function (item, escape) {
          return (
            '<option value="' + item.key + '">' + escape(item.key) + "</option>"
          );
        },
      },
      load: function (query, callback) {
        if (!query.length) return callback();
        // Mengambil data melalui AJAX jQuery
        $.ajax({
          url: "/data-areas",
          type: "GET",
          dataType: "json",
          data: {
            q: query,
          },
          success: function (response) {
            if (response.length > 0) {
              callback(response);
            }
            // console.log(response);
          },
          error: function (error) {
            console.error("Kesalahan pengambilan data:", error);
          },
        });
      },
    });
  });
}

/**
 * https://jtcargo.live/id/member/online-order
 */
if (
  document.querySelectorAll('[data-component="save-order-transaction"]')
    .length > 0
) {
  document.addEventListener("DOMContentLoaded", function () {
    $(function () {
      let field_required = $("#error_field_required").attr(
        "data-field_required"
      );
      let field_number = $("#error_field_numeric").attr("data-field_numeric");
      var validationMessages = {
        required: field_required,
        number: field_number,
      };
      var save_form_order = $("[data-form='save-order-form']");
      save_form_order.find("input, select").on("input change", function (e) {
        e.preventDefault();
        var field = $(this);
        var fieldName = field.attr("name");
        var errorMessage = "";
        switch (fieldName) {
          case "sender_name":
          case "sender_city":
          case "sender_address":
          case "recipient_name":
          case "recipient_city":
          case "recipient_address":
          case "package_product_type_id":
          case "package_item_name":
          case "package_payment_id":
            errorMessage =
              field.val() === "" ? transValidation("required") : "";
            break;
          case "sender_phone":
          case "sender_postcode":
          case "recipient_phone":
          case "recipient_postcode":
          case "package_item_quantity":
          case "package_item_weight":
            errorMessage =
              isNaN(field.val()) || field.val() === ""
                ? transValidation("number")
                : "";
            break;
        }

        if (errorMessage !== "") {
          showError(field, errorMessage);
        } else {
          hideError(field);
        }
        if (allFieldsFilled()) {
          $("[data-btn='save-order-form']")
            .prop("disabled", false)
            .removeClass("btn-dark")
            .addClass("btn-primary");
        } else {
          $("[data-btn='save-order-form']")
            .prop("disabled", true)
            .removeClass("btn-primary")
            .addClass("btn-dark");
        }
      });
      save_form_order.on("click", "[data-btn='save-order-form']", function (e) {
        e.preventDefault();
        var hasError = false;
        save_form_order.find("input, select").each(function () {
          var field = $(this);
          var fieldName = field.attr("name");
          var errorMessage = "";

          switch (fieldName) {
            case "sender_name":
            case "sender_city":
            case "sender_address":
            case "recipient_name":
            case "recipient_city":
            case "recipient_address":
            case "package_product_type_id":
            case "package_item_name":
            case "package_payment_id":
              errorMessage =
                field.val() === "" ? transValidation("required") : "";
              break;
            case "sender_phone":
            case "sender_postcode":
            case "recipient_phone":
            case "recipient_postcode":
            case "package_item_quantity":
            case "package_item_weight":
              errorMessage =
                isNaN(field.val()) || field.val() === ""
                  ? transValidation("number")
                  : "";
              break;
          }

          if (errorMessage !== "") {
            var $form = $(this).closest(save_form_order);
            $form.find(".selectize-input.items.not-full").addClass("invalid");
            // var $errorMessage = $(
            //     '<span class="error-message" id="recipient_city-error">The column is mandatory.</span>'
            // );
            // $form.find(".single.selectize-dropdown").after(
            //     '<span class="error-message"></span>').text(
            //     message);
            // form.next('.error-message').text(message);
            showError(field, errorMessage);
            hasError = true;
          } else {
            hideError(field);
          }
        });
        if (!hasError) {
          $("#loading-image-screen").modal("show");
          save_form_order.submit();
        } else {
          console.log(
            "Form has errors. Please correct them before submitting."
          );
        }
      });

      function allFieldsFilled() {
        var allFilled = true;
        $("input, select").each(function () {
          if ($(this).val() === "" && $(this).prop("required")) {
            allFilled = false;
            return false; // Exit the loop early if any required field is not filled
          }
        });
        return allFilled;
      }

      function checkStatus(field) {
        var fieldName = field.attr("name");
        var errorMessage = "";
        if (field.val() === "") {
          errorMessage = transValidation("required");
        }
        if (errorMessage !== "") {
          showError(field, errorMessage);
        } else {
          hideError(field);
        }
      }

      function showError(field, message) {
        field.addClass("invalid");
        field.next(".error-message").text(message);
      }

      function hideError(field) {
        field.removeClass("invalid");
        field.next(".error-message").text("");
      }

      function transValidation(key) {
        return validationMessages[key];
      }
    });

    $('[data-component="save-order-transaction"]').on(
      "change",
      "#sender_add_contact",
      function () {
        if ($(this).is(":checked")) {
          $(this).attr("value", true);
        } else {
          $(this).attr("value", false);
        }
      }
    );
    $('[data-component="save-order-transaction"]').on(
      "change",
      "#recipient_add_contact",
      function () {
        if ($(this).is(":checked")) {
          $(this).attr("value", true);
        } else {
          $(this).attr("value", false);
        }
      }
    );
    $(".form-custom-selectize")
      .find('[data-toggle="selectize-product-type"]')
      .selectize({
        placeholder: "Select Item",
      });
    $('[data-component="save-order-transaction"]').on(
      "click",
      "[data-user-booklist]",
      function () {
        let dataUser = $(this).attr("data-user-booklist");
        if (dataUser == "sender") {
          $("#modal-contact").find("h4.modal-title").text("Informasi Pengirim");
        } else {
          $("#modal-contact").find("h4.modal-title").text("Informasi Penerima");
        }

        var url = $('[data-component="form-address-booklist"]').attr("action");
        $.ajaxSetup({
          headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
          },
        });
        $.ajax({
          url: url,
          method: "POST",
          data: {
            type_user: $(this).attr("data-user-booklist"),
          },
          beforeSend: function () {
            $("#loading-image-screen").modal("show");
          },
          success: function (result) {
            console.log(result);
            $("#loading-image-screen").modal("hide");
            $("#modal-contact").modal("show");

            var html = "";
            if (result.data === 0) {
              html += `<div class="flex justify-center items-center h-screen"><p>${result.error}</p></div>`;
            } else {
              $.each(result.data, function (key, data) {
                var encodedData = encodeURIComponent(JSON.stringify(data));
                html += `<div class="mb-6 text-sm form-check form-check--information">
                          <input type="hidden" name="type-user" value="${data.type}">
                          <input type="radio" name="selectPengirim" id="selectPengirim${key}" value="${encodedData}" class="mt-1">
                          <label for="selectPengirim${key}">
                              <span class="dots"></span>
                              <div class="flex-1 pl-4 information">
                                  <h6>${data.name} - ${data.mobile}</h6>
                                  <div class="information-address">
                                      <p> ${data.detailedAddress}, ${data.areaName} ', ' ${data.cityName}', '${data.provinceName} - ${data.postalCode}</p>
                                  </div>
                              </div>
                          </label>
                      </div>`;
              });
              html += `<div class="form-submit"><button type="submit" id="choose_address" class="w-full btn btn-primary">SelectAddress</button></div>`;
              $('[data-component="form-address-booklist"]').html(html);
            }
          },
        }).done(function () {
          jQuery.ready();
        });
      }
    );
    $("#modal-contact").on("click", "#choose_address", function (e) {
      e.preventDefault();
      let $order_form = $(".order-form__card-content");
      let type_user = $("input[name='type-user']").val();
      let selectedRadio = $("input[name='selectPengirim']:checked");
      if (selectedRadio.length > 0) {
        var getValue = selectedRadio.val();
        var decodedValue = decodeURIComponent(getValue);
        var data = JSON.parse(decodedValue);
        if (type_user == 1) {
          $order_form.find("input[name='dataSender']").val(decodedValue);
          var dataArea =
            data.provinceName + "," + data.cityName + "," + data.areaName;
          setDefaultSenderSelectize(dataArea);
          $order_form.find("input[name='sender_name']").val(data.name);
          $order_form.find("input[name='sender_name']").removeClass("invalid");
          $order_form
            .find("input[name='sender_name']")
            .next(".error-message")
            .text("");
          $order_form.find("input[name='sender_phone']").val(data.mobile);
          $order_form.find("input[name='sender_phone']").removeClass("invalid");
          $order_form
            .find("input[name='sender_phone']")
            .next(".error-message")
            .text("");
          $order_form.find("input[name='sender_city']").val(dataArea);
          $order_form.find("input[name='sender_city']").removeClass("invalid");
          $order_form
            .find("input[name='sender_city']")
            .next(".error-message")
            .text("");
          $order_form
            .find("input[name='sender_address']")
            .val(data.detailedAddress);
          $order_form
            .find("input[name='sender_address']")
            .removeClass("invalid");
          $order_form
            .find("input[name='sender_address']")
            .next(".error-message")
            .text("");
          $order_form
            .find("input[name='sender_postcode']")
            .val(data.postalCode);
          $order_form
            .find("input[name='sender_postcode']")
            .removeClass("invalid");
          $order_form
            .find("input[name='sender_postcode']")
            .next(".error-message")
            .text("");
          $order_form
            .find("input[name='selectPengirim']")
            .prop("checked", false);
          $order_form
            .find("input[name='selectPengirim']")
            .removeClass("invalid");
          $order_form
            .find("input[name='selectPengirim']")
            .next(".error-message")
            .text("");

          $("#modal-contact").modal("hide");
        } else {
          $order_form.find("input[name='dataRecipient']").val(decodedValue);
          var dataArea =
            data.provinceName + "," + data.cityName + "," + data.areaName;
          setDefaultRecipientSelectize(dataArea);
          $order_form.find("input[name='recipient_name']").val(data.name);
          $order_form
            .find("input[name='recipient_name']")
            .removeClass("invalid");
          $order_form
            .find("input[name='recipient_name']")
            .next(".error-message")
            .text("");
          $order_form.find("input[name='recipient_phone']").val(data.mobile);
          $order_form
            .find("input[name='recipient_phone']")
            .removeClass("invalid");
          $order_form
            .find("input[name='recipient_phone']")
            .next(".error-message")
            .text("");
          $order_form.find("input[name='recipient_city']").val(dataArea);
          $order_form
            .find("input[name='recipient_city']")
            .removeClass("invalid");
          $order_form
            .find("input[name='recipient_city']")
            .next(".error-message")
            .text("");
          $order_form
            .find("input[name='recipient_address']")
            .val(data.detailedAddress);
          $order_form
            .find("input[name='recipient_address']")
            .removeClass("invalid");
          $order_form
            .find("input[name='recipient_address']")
            .next(".error-message")
            .text("");
          $order_form
            .find("input[name='recipient_postcode']")
            .val(data.postalCode);
          $order_form
            .find("input[name='recipient_postcode']")
            .removeClass("invalid");
          $order_form
            .find("input[name='recipient_postcode']")
            .next(".error-message")
            .text("");
          $order_form
            .find("input[name='selectPengirim']")
            .prop("checked", false);
          $order_form
            .find("input[name='selectPengirim']")
            .removeClass("invalid");
          $order_form
            .find("input[name='selectPengirim']")
            .next(".error-message")
            .text("");
          $("#modal-contact").modal("hide");
        }
      } else {
        alert("Belum ada alamat yang dipilih!.");
      }
    });
    setDefaultSenderSelectize();
    setDefaultRecipientSelectize();

    function setDefaultSenderSelectize(value = "") {
      var selectizeInput = $("[data-toggle='selectize-area-sender']").selectize(
        {
          valueField: "key",
          labelField: "key",
          searchField: "key",
          preload: true,
          maxItems: 1,
          create: false,
          render: {
            item: function (item, escape) {
              return "<div>" + escape(item.key) + "</div>";
            },
          },
          load: function (query, callback) {
            if (!query.length) return callback();
            var self = this;
            $.ajax({
              url: "/data-areas",
              type: "GET",
              dataType: "json",
              data: {
                q: query,
              },
              success: function (response) {
                if (response.length > 0) {
                  callback(response);
                }
                // console.log(response);
              },
              error: function (error) {
                console.error("Kesalahan pengambilan data:", error);
              },
            });
          },
        }
      );

      selectizeInput[0].selectize.setValue(value);
    }

    function setDefaultRecipientSelectize(value = "") {
      var selectizeInput = $(
        "[data-toggle='selectize-area-recipient']"
      ).selectize({
        valueField: "key",
        labelField: "key",
        searchField: "key",
        preload: true,
        maxItems: 1,
        create: false,
        render: {
          item: function (item, escape) {
            return "<div>" + escape(item.key) + "</div>";
          },
        },
        load: function (query, callback) {
          if (!query.length) return callback();
          var self = this;
          $.ajax({
            url: "/data-areas",
            type: "GET",
            dataType: "json",
            data: {
              q: query,
            },
            success: function (response) {
              if (response.length > 0) {
                callback(response);
              }
              // console.log(response);
            },
            error: function (error) {
              console.error("Kesalahan pengambilan data:", error);
            },
          });
        },
      });

      selectizeInput[0].selectize.setValue(value);
    }

    $('[data-component="save-order-transaction"]').on(
      "click",
      "#sender_delete",
      function () {
        $("input[name*='sender_']").val("");
      }
    );
    $('[data-component="save-order-transaction"]').on(
      "click",
      "#recipient_delete",
      function () {
        $("input[name*='recipient_']").val("");
      }
    );
    $('[data-component="save-order-transaction"]').on(
      "click",
      "#package_delete",
      function () {
        $("input[name*='package_']").val("");
        $("textarea[name='package_notes']").val("");
      }
    );

    // $('[data-component="save-order-transaction"]').on(
    //   "change",
    //   "#sender_add_contact",
    //   function () {
    //     if ($(this).is(":checked")) {
    //       $(this).attr("value", true);
    //     } else {
    //       $(this).attr("value", false);
    //     }
    //   }
    // );
    // $('[data-component="save-order-transaction"]').on(
    //   "change",
    //   "#recipient_add_contact",
    //   function () {
    //     if ($(this).is(":checked")) {
    //       $(this).attr("value", true);
    //     } else {
    //       $(this).attr("value", false);
    //     }
    //   }
    // );
    // $(".form-custom-selectize")
    //   .find('[data-toggle="selectize-product-type"]')
    //   .selectize({
    //     placeholder: "Select Item",
    //   });
    // $('[data-component="save-order-transaction"]').on(
    //   "click",
    //   "[data-user-booklist]",
    //   function () {
    //     var url = $('[data-component="form-address-booklist"]').attr("action");
    //     $.ajaxSetup({
    //       headers: {
    //         "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
    //       },
    //     });
    //     $.ajax({
    //       url: url,
    //       method: "POST",
    //       data: {
    //         type_user: $(this).attr("data-user-booklist"),
    //       },
    //       beforeSend: function () {
    //         $("#loading-image-screen").modal("show");
    //       },
    //       success: function (result) {
    //         // console.log(result);
    //         $("#loading-image-screen").modal("hide");
    //         $("#modal-contact").modal("show");
    //         var html = "";
    //         if (result.data === 0) {
    //           html += `<div class="flex justify-center items-center h-screen"><p>${result.error}</p></div>`;
    //         } else {
    //           $.each(result.data, function (key, data) {
    //             var encodedData = encodeURIComponent(JSON.stringify(data));
    //             html += `<div class="mb-6 text-sm form-check form-check--information">
    //                                 <input type="hidden" name="type-user" value="${data.type}">
    //                                 <input type="radio" name="selectPengirim" id="selectPengirim${key}" value="${encodedData}" class="mt-1">
    //                                 <label for="selectPengirim${key}">
    //                                     <span class="dots"></span>
    //                                     <div class="flex-1 pl-4 information">
    //                                         <h6>${data.name} - ${data.mobile}</h6>
    //                                         <div class="information-address">
    //                                             <p> ${data.detailedAddress}, ${data.areaName} ', ' ${data.cityName}', '${data.provinceName} - ${data.postalCode}</p>
    //                                         </div>
    //                                     </div>
    //                                 </label>
    //                             </div>`;
    //           });
    //           html += `<div class="form-submit"><button type="submit" id="choose_address" class="w-full btn btn-primary">SelectAddress</button></div>`;
    //           $('[data-component="form-address-booklist"]').html(html);
    //         }
    //       },
    //     }).done(function () {
    //       jQuery.ready();
    //     });
    //   }
    // );
    // $("#modal-contact").on("click", "#choose_address", function (e) {
    //   e.preventDefault();
    //   let $order_form = $(".order-form__card-content");
    //   let type_user = $("input[name='type-user']").val();
    //   let selectedRadio = $("input[name='selectPengirim']:checked");
    //   if (selectedRadio.length > 0) {
    //     var getValue = selectedRadio.val();
    //     var decodedValue = decodeURIComponent(getValue);
    //     var data = JSON.parse(decodedValue);
    //     if (type_user == 1) {
    //       $order_form.find("input[name='dataSender']").val(decodedValue);
    //       var dataArea =
    //         data.provinceName + "," + data.cityName + "," + data.areaName;
    //       $order_form.find("input[name='sender_name']").val(data.name);
    //       $order_form.find("input[name='sender_phone']").val(data.mobile);
    //       $order_form.find("input[name='sender_city']").val(dataArea);
    //       $order_form
    //         .find("input[name='sender_address']")
    //         .val(data.detailedAddress);
    //       $order_form
    //         .find("input[name='sender_postcode']")
    //         .val(data.postalCode);
    //       $order_form
    //         .find("input[name='selectPengirim']")
    //         .prop("checked", false);
    //       $("#modal-contact").modal("hide");
    //     } else {
    //       $order_form.find("input[name='dataRecipient']").val(decodedValue);
    //       var dataArea =
    //         data.provinceName + "," + data.cityName + "," + data.areaName;
    //       $order_form.find("input[name='recipient_name']").val(data.name);
    //       $order_form.find("input[name='recipient_phone']").val(data.mobile);
    //       $order_form.find("input[name='recipient_city']").val(dataArea);
    //       $order_form
    //         .find("input[name='recipient_address']")
    //         .val(data.detailedAddress);
    //       $order_form
    //         .find("input[name='recipient_postcode']")
    //         .val(data.postalCode);
    //       $order_form
    //         .find("input[name='selectPengirim']")
    //         .prop("checked", false);
    //       $("#modal-contact").modal("hide");
    //     }
    //   } else {
    //     alert("Belum ada alamat yang dipilih!.");
    //   }
    // });
    // $('[data-component="save-order-transaction"]').on(
    //   "click",
    //   "#sender_delete",
    //   function () {
    //     $("input[name*='sender_']").val("");
    //   }
    // );
    // $('[data-component="save-order-transaction"]').on(
    //   "click",
    //   "#recipient_delete",
    //   function () {
    //     $("input[name*='recipient_']").val("");
    //   }
    // );
    // $('[data-component="save-order-transaction"]').on(
    //   "click",
    //   "#package_delete",
    //   function () {
    //     $("input[name*='package_']").val("");
    //     $("textarea[name='package_notes']").val("");
    //   }
    // );
  });
}
/**
 * https://jtcargo.live/id/check-price
 */

if (
  document.querySelectorAll('[data-component="freight-calculation"]').length > 0
) {
  document.addEventListener("DOMContentLoaded", function () {
    var startSelectize = $(
      "[data-toggle='selectize-start-area-select']"
    ).selectize({
      valueField: "key",
      labelField: "key",
      searchField: "key",
      preload: true,
      // options: [],
      create: false,
      // onChange: function(value) {
      //     alert(value);
      // },
      render: {
        option: function (item, escape) {
          return (
            '<option value="' + item.key + '">' + escape(item.key) + "</option>"
          );
        },
      },
      load: function (query, callback) {
        if (!query.length) return callback();
        // Mengambil data melalui AJAX jQuery
        $.ajax({
          url: "/data-areas",
          type: "GET",
          dataType: "json",
          data: {
            q: query,
          },
          success: function (response) {
            if (response.length > 0) {
              callback(response);
            }
            // console.log(response);
          },
          error: function (error) {
            console.error("Kesalahan pengambilan data:", error);
          },
        });
      },
    });
    var endSelectize = $("[data-toggle='selectize-end-area-select']").selectize(
      {
        valueField: "key",
        labelField: "key",
        searchField: "key",
        preload: true,
        // options: [],
        create: false,
        // onChange: function(value) {
        //     alert(value);
        // },
        render: {
          option: function (item, escape) {
            return (
              '<option value="' +
              item.key +
              '">' +
              escape(item.key) +
              "</option>"
            );
          },
        },
        load: function (query, callback) {
          if (!query.length) return callback();
          $.ajax({
            url: "/data-areas",
            type: "GET",
            dataType: "json",
            data: {
              q: query,
            },
            success: function (response) {
              if (response.length > 0) {
                callback(response);
              }
              // console.log(response);
            },
            error: function (error) {
              console.error("Kesalahan pengambilan data:", error);
            },
          });
        },
      }
    );
    $(".section-dialog").on("change", "#methode-pengiriman", function (e) {
      e.preventDefault();
      var data_code = $(this).find(":selected").attr("data-code");
      $(".section-dialog").find("input[name='code']").val(data_code);
    });
  });
}

/**
 * https://jtcargo.live/id/check-outlet
 */
if (
  document.querySelectorAll('[data-component="entry-outlet-result"]').length > 0
) {
  document.addEventListener("DOMContentLoaded", function () {
    ((g) => {
      var h,
        a,
        k,
        p = "The Google Maps JavaScript API",
        c = "google",
        l = "importLibrary",
        q = "__ib__",
        m = document,
        b = window;
      b = b[c] || (b[c] = {});
      var d = b.maps || (b.maps = {}),
        r = new Set(),
        e = new URLSearchParams(),
        u = () =>
          h ||
          (h = new Promise(async (f, n) => {
            await (a = m.createElement("script"));
            e.set("libraries", [...r] + "");
            for (k in g)
              e.set(
                k.replace(/[A-Z]/g, (t) => "_" + t[0].toLowerCase()),
                g[k]
              );
            e.set("callback", c + ".maps." + q);
            a.src = `https://maps.${c}apis.com/maps/api/js?` + e;
            d[q] = f;
            a.onerror = () => (h = n(Error(p + " could not load.")));
            a.nonce = m.querySelector("script[nonce]")?.nonce || "";
            m.head.append(a);
          }));
      d[l]
        ? console.warn(p + " only loads once. Ignoring:", g)
        : (d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)));
    })({
      key: "AIzaSyBbfJ1gJBbVqjD-ba2_xBEMklRpUNGIX8s",
      v: "weekly",
      // Use the 'v' parameter to indicate the version to use (weekly, beta, alpha, etc.).
      // Add other bootstrap parameters as needed, using camel case.
    });
    // ((g) => {
    //   var h,
    //     a,
    //     k,
    //     p = "The Google Maps JavaScript API",
    //     c = "google",
    //     l = "importLibrary",
    //     q = "__ib__",
    //     m = document,
    //     b = window;
    //   b = b[c] || (b[c] = {});
    //   var d = b.maps || (b.maps = {}),
    //     r = new Set(),
    //     e = new URLSearchParams(),
    //     u = () =>
    //       h ||
    //       (h = new Promise(async (f, n) => {
    //         await (a = m.createElement("script"));
    //         e.set("libraries", [...r] + "");
    //         for (k in g)
    //           e.set(
    //             k.replace(/[A-Z]/g, (t) => "_" + t[0].toLowerCase()),
    //             g[k]
    //           );
    //         e.set("callback", c + ".maps." + q);
    //         a.src = `https://maps.${c}apis.com/maps/api/js?` + e;
    //         d[q] = f;
    //         a.onerror = () => (h = n(Error(p + " could not load.")));
    //         a.nonce = m.querySelector("script[nonce]")?.nonce || "";
    //         m.head.append(a);
    //       }));
    //   d[l]
    //     ? console.warn(p + " only loads once. Ignoring:", g)
    //     : (d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)));
    // })({ key: "AIzaSyBbfJ1gJBbVqjD-ba2_xBEMklRpUNGIX8s", v: "weekly" });
    let map;

    async function initMap() {
      const { Map } = await google.maps.importLibrary("maps");

      map = new Map(document.getElementById("locatorMapsTargetNew"), {
        center: {
          lat: -34.397,
          lng: 150.644,
        },
        zoom: 8,
      });
    }

    initMap();

    let defaultValue = $("input[name='selectize-default-value']").val();

    var selectizeSelectOutlet = $(
      "[data-toggle='selectize-area-select-outlet']"
    ).selectize({
      valueField: "key",
      labelField: "key",
      searchField: "key",
      preload: true,
      // options: [],
      create: false,
      render: {
        option: function (item, escape) {
          return (
            '<option value="' + item.key + '">' + escape(item.key) + "</option>"
          );
        },
      },
      load: function (query, callback) {
        if (!query.length) return callback();
        $.ajax({
          url: "/data-areas",
          type: "GET",
          dataType: "json",
          data: {
            q: query,
          },
          success: function (response) {
            if (response.length > 0) {
              callback(response);
            }
            // console.log(response);
          },
          error: function (error) {
            console.error("Kesalahan pengambilan data:", error);
          },
        });
      },
    });
    // Tambahkan opsi default dan set nilai default
    selectizeSelectOutlet[0].selectize.addOption({
      key: defaultValue,
    });
    selectizeSelectOutlet[0].selectize.setValue(defaultValue);

    $(function () {
      // var initMap = initMap();

      function getOutlets(url, formData) {
        // console.log(formData);
        $.ajaxSetup({
          headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
          },
        });
        $.ajax({
          url: url,
          method: "POST",
          data: formData,
          success: function (result) {
            $("#loading-image-screen").modal("hide");

            if (result.errors) {
              $.each(result.errors, function (key, value) {
                $("#modal-confirm-outlet").modal("show");
                $("p#wording-modal-confirm-outlet").html(value);
              });
            } else {
              if (result.type === 201) {
                if ($.trim(result.data.data).length === 0) {
                  $("#modal-confirm-outlet").modal("show");
                  $("p#wording-modal-confirm-outlet").html("Data is empty");
                } else {
                  $("#empty-outlet-list").remove();
                  // console.log(result.data.data);
                  displayMarkers(result.data.data);
                  var view = "";
                  $.each(result.data.data, function (idx, data) {
                    view += `<div class="overflow-hidden p-4 rounded-sm card card--maps bg-colorffffff">
                <div class="flex mb-4 card-content">
                    <div class="overflow-hidden w-20 h-20 rounded-sm images">
                        <img class="lazyload"
                            data-src="/assets/images/doorDefault.71b05114.png"
                            alt="J&T Cargo" />
                    </div>
                    <div class="flex-1 pl-2 content">
                        <h6>${data.name}</h6>
                        <div class="-my-1 text-sm">
                            <div class="flex items-start py-1 content-address">
                                <span class="icon icon-pin"></span>
                                <div class="flex-1 pl-2">
                                    <p>
                                        ${data.address}
                                    </p>
                                </div>
                            </div>
                            <div class="flex items-start py-1 content-phone">
                                <span class="icon icon-phone"></span>
                                <div class="flex-1 pl-2">
                                    <p>
                                        ${data.businessTelephone}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="grid grid-cols-2 gap-2 sm:grid-cols-1">
                        <div>
                            <button name="outlet-point" data-mitra="${escape(
                              JSON.stringify(data)
                            )}"
                                class="w-full btn btn-primary btn-sm">Outlet
                                Point</button>
                        </div>
                        <div>
                            <a href="https://www.google.com/maps?q=${encodeURIComponent(
                              data.address
                            )}" target="_blank" class="w-full btn btn-primary btn-sm">Direction</a>
                        </div>
                    </div>
                </div>
            </div>`;
                  });
                  $("#display-outlet-list").html(view);

                  $("button[name='outlet-point']").on("click", function (e) {
                    e.preventDefault();
                    var mitraData = JSON.parse(
                      unescape($(this).attr("data-mitra"))
                    );
                    getZoomLocation(mitraData);
                  });
                }
              } else if (result.type === 401) {
                $("#modal-confirm-outlet").modal("show");
                $("p#wording-modal-confirm-outlet").html(result.data.msg);
              }
            }
          },
          error: function (xhr, status, error) {
            // Tanggapan AJAX gagal, tangani kesalahan di sini
          },
        }).done(function () {
          //trigger any doc ready scripts we may have just loaded
          jQuery.ready();
        });
      }
      $("#form-check-outlet-location").on(
        "click",
        "#getNearMyArea",
        function (e) {
          e.preventDefault();
          // $("#loading-image-screen").modal("show");

          getLocation();
        }
      );
      $("#form-check-outlet-location").on("submit", function (e) {
        e.preventDefault();
        $("#loading-image-screen").modal("show");
        $("#display-outlet-list").html("");
        // $("#empty-outlet-list").show();
        var mitra_value = $("input[name='selectMitra']:checked").val();
        var $form = $(this);
        var url = $form.attr("action");
        var formData = $form.serializeArray();
        formData.push(
          {
            name: "type",
            value: "search",
          },
          {
            name: "mitra_selected",
            value: mitra_value,
          }
        );
        getOutlets(url, formData);
      });

      function redirectToGoogleMaps(address) {
        // Membuat URL Google Maps dengan alamat sebagai parameter
        var googleMapsUrl =
          "https://www.google.com/maps?q=" + encodeURIComponent(address);
        // Membuka tautan dalam tab baru
        // window.open(googleMapsUrl, '_blank');
        // Melakukan redirect
        window.location.href = googleMapsUrl;
      }

      function getZoomLocation(dataOutlet) {
        // var initMap = initMap();
        // Tambahkan marker ke peta
        getCoordinatesFromAddress(dataOutlet, function (location) {
          var marker = new google.maps.Marker({
            position: location,
            map: map,
            icon: {
              url: "/assets/images/green-google-maps-200.png",
              scaledSize: new google.maps.Size(70, 70),
            },
            title: dataOutlet.address,
          });
          var contentString =
            '<div id="content">' +
            '<h1 id="firstHeading" class="firstHeading">' +
            dataOutlet.name +
            "</h1>" +
            "<p>" +
            dataOutlet.address +
            "</p>" +
            "<p>Business Type: " +
            dataOutlet.areaDesc +
            "</p>" +
            "</div>";
          var infowindow = new google.maps.InfoWindow();
          map.setZoom(17);
          map.setCenter(marker.getPosition());
          infowindow.setContent(contentString);
          infowindow.open(map, marker);
        });
      }

      function getCoordinatesFromAddress(dataArray, callback) {
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode(
          {
            address: dataArray.address,
          },
          function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
              var location = results[0].geometry.location;
              // console.log('LOCATION >>>', location);
              callback(location);
            } else {
              console.error(
                "Geocode was not successful for the following reason: " + status
              );
            }
          }
        );
      }

      // initMap();

      function displayMarkers(addresses) {
        // var initMap = initMap();
        addresses.forEach(function (data) {
          getCoordinatesFromAddress(data, function (location) {
            // console.log("LOCATION >>>>", data);
            var marker = new google.maps.Marker({
              position: location,
              map: map,
              icon: {
                url: "/assets/images/green-google-maps-200.png",
                scaledSize: new google.maps.Size(70, 70),
              },
              title: data.address,
            });
            var contentString =
              '<div id="content">' +
              '<h1 id="firstHeading" class="firstHeading">' +
              data.name +
              "</h1>" +
              "<p>" +
              data.address +
              "</p>" +
              "<p>Business Type: " +
              data.areaDesc +
              "</p>" +
              "</div>";
            var infowindow = new google.maps.InfoWindow();

            marker.addListener("click", function () {
              // map.setZoom(10);
              map.setCenter(marker.getPosition());
              infowindow.setContent(contentString);
              infowindow.open(map, marker);
            });
          });
        });
      }

      function getLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(showPosition);
        } else {
          console.log("Geolocation is not supported by this browser.");
        }
      }

      function showPosition(position) {
        var latitude = position.coords.latitude;
        var longitude = position.coords.longitude;

        sendLocationData(latitude, longitude);
      }

      function sendLocationData(latitude, longitude) {
        var $form = $("#form-check-outlet-location");
        var url = $form.attr("action");
        var formData = {
          type: "near-area",
          latitude: latitude,
          longitude: longitude,
        };
        getOutlets(url, formData);
      }
    });
  });
}
/**
 *  @page /check-price
 */
if (
  document.querySelectorAll('[data-component="check-ongkir-page"]').length > 0
) {
  document.addEventListener("DOMContentLoaded", function () {
    $(function () {
      let deliveryDefault = $("input[name='selectize-default-delivery']").val();
      let destinationDefault = $(
        "input[name='selectize-default-destination']"
      ).val();

      var $deliverySelectize = $(
        "[data-toggle='selectize-area-input-delivery']"
      ).selectize({
        valueField: "key",
        labelField: "key",
        searchField: "key",
        preload: true,
        onChange: function (value) {
          // console.log('CHANGE DESTINATION');
          // Panggil fungsi muatNilaiDropdown jika kedua dropdown memiliki nilai yang terpilih
          // if ($deliverySelectize[0].selectize.getValue() &&
          //     $destinationSelectize[0].selectize.getValue()) {
          //     showShippingMethod();
          // }
        },
        // options: [],
        create: false,
        render: {
          option: function (item, escape) {
            return (
              '<option value="' +
              item.key +
              '">' +
              escape(item.key) +
              "</option>"
            );
          },
        },
        load: function (query, callback) {
          if (!query.length) return callback();
          $.ajax({
            url: "/data-areas",
            type: "GET",
            dataType: "json",
            data: {
              q: query,
            },
            success: function (response) {
              if (response.length > 0) {
                callback(response);
              }
              // console.log(response);
            },
            error: function (error) {
              console.error("Kesalahan pengambilan data:", error);
            },
          });
        },
      });
      $deliverySelectize[0].selectize.addOption({
        key: deliveryDefault,
      });
      $deliverySelectize[0].selectize.setValue(deliveryDefault);
      // console.log('DESTINATION DEFAULT', destinationDefault);
      var $destinationSelectize = $(
        "[data-toggle='selectize-area-input-destination']"
      ).selectize({
        valueField: "key",
        labelField: "key",
        searchField: "key",
        preload: true,
        // options: [],
        onChange: function (value) {
          // Panggil fungsi muatNilaiDropdown jika kedua dropdown memiliki nilai yang terpilih
          // if ($deliverySelectize[0].selectize.getValue() &&
          //     $destinationDefault[0].selectize.getValue()) {
          //     showShippingMethod();
          // }
        },
        create: false,
        render: {
          option: function (item, escape) {
            return (
              '<option value="' +
              item.key +
              '">' +
              escape(item.key) +
              "</option>"
            );
          },
        },
        load: function (query, callback) {
          if (!query.length) return callback();
          $.ajax({
            url: "/data-areas",
            type: "GET",
            dataType: "json",
            data: {
              q: query,
            },
            success: function (response) {
              if (response.length > 0) {
                callback(response);
              }
              // console.log(response);
            },
            error: function (error) {
              console.error("Kesalahan pengambilan data:", error);
            },
          });
        },
      });

      $destinationSelectize[0].selectize.addOption({
        key: destinationDefault,
      });
      $destinationSelectize[0].selectize.setValue(destinationDefault);
      var startCity = $deliverySelectize[0].selectize.getValue();
      // console.log('START CITY >>>>>', startCity);
      var endCity = $destinationSelectize[0].selectize.getValue();
      // console.log('END CITY >>>>>', endCity);

      function callback(res, ajaxCallback) {
        // console.log("callback:", res);
        if (res.ret === 0) {
          var str =
            "&#8203;``【oaicite:3】``&#8203;->&#8203;``【oaicite:2】``&#8203;      &#8203;``【oaicite:1】``&#8203;->&#8203;``【oaicite:0】``&#8203;";
          var ipt = document.createElement("input");
          ipt.value = str;
          document.body.appendChild(ipt);
          ipt.select();
          document.execCommand("Copy");
          document.body.removeChild(ipt);

          ajaxCallback(res);
        }
      }
      var validationMessages = {
        required: $("#error_field_required").attr("data-field_required"),
        number: $("#error_field_numeric").attr("data-field_numeric"),
      };
      var submitButton = $("#CaptchaId");
      submitButton
        .prop("disabled", true)
        .removeClass("btn-primary")
        .addClass("btn-dark");

      function allFieldsFilled() {
        var allFilled = true;
        $("input, select").each(function () {
          if ($(this).val() === "" && $(this).prop("required")) {
            allFilled = false;
            return false; // Exit the loop early if any required field is not filled
          }
        });
        return allFilled;
      }
      $("input, select").on("input change", function () {
        var field = $(this);
        var fieldName = field.attr("name");
        var errorMessage = "";
        switch (fieldName) {
          case "startLocation":
            errorMessage =
              field.val() === "" ? transValidation("required") : "";
            break;
          case "endLocation":
            errorMessage =
              field.val() === "" ? transValidation("required") : "";
            break;
          case "packageWeight":
            errorMessage =
              isNaN(field.val()) || field.val() === ""
                ? transValidation("number")
                : "";
            break;
          case "packageLength":
            errorMessage =
              isNaN(field.val()) || field.val() === ""
                ? transValidation("number")
                : "";
            break;
          case "packageWidth":
            errorMessage =
              isNaN(field.val()) || field.val() === ""
                ? transValidation("number")
                : "";
            break;
          case "packageHigh":
            errorMessage =
              isNaN(field.val()) || field.val() === ""
                ? transValidation("number")
                : "";
            break;
          case "paymentMethod":
            errorMessage =
              field.val() === "" ? transValidation("required") : "";
            break;
          case "productType":
            errorMessage =
              field.val() === "" ? transValidation("required") : "";
            break;
        }

        if (errorMessage !== "") {
          showError(field, errorMessage);
        } else {
          hideError(field);
        }
        if (allFieldsFilled()) {
          submitButton
            .prop("disabled", false)
            .removeClass("btn-dark")
            .addClass("btn-primary");
        } else {
          submitButton
            .prop("disabled", true)
            .removeClass("btn-primary")
            .addClass("btn-dark");
        }
        // checkFields();
      });

      function showError(field, message) {
        field.addClass("invalid");
        field.next(".error-message").text(message);
      }

      function hideError(field) {
        field.removeClass("invalid");
        field.next(".error-message").text("");
      }

      function transValidation(key) {
        return validationMessages[key];
      }
      $('[data-component="check-ongkir-page"]').on(
        "submit",
        "#submitCalculate",
        function (e) {
          e.preventDefault();
          $("input, select").each(function () {
            var field = $(this);
            var fieldName = field.attr("name");
            var errorMessage = "";

            // Check specific validations for each field before submission
            switch (fieldName) {
              case "startLocation":
                errorMessage =
                  field.val() === "" ? transValidation("required") : "";
                break;
              case "endLocation":
                errorMessage =
                  isNaN(field.val()) || field.val() === ""
                    ? transValidation("required")
                    : "";
              case "packageWeight":
                errorMessage =
                  isNaN(field.val()) || field.val() === ""
                    ? transValidation("number")
                    : "";
                break;
              case "packageLength":
                errorMessage =
                  isNaN(field.val()) || field.val() === ""
                    ? transValidation("number")
                    : "";
                break;
              case "packageWidth":
                errorMessage =
                  isNaN(field.val()) || field.val() === ""
                    ? transValidation("number")
                    : "";
                break;
              case "packageHigh":
                errorMessage =
                  isNaN(field.val()) || field.val() === ""
                    ? transValidation("number")
                    : "";
                break;
              case "paymentMethod":
                errorMessage =
                  field.val() === "" ? transValidation("required") : "";
                break;
              case "productType":
                errorMessage =
                  field.val() === "" ? transValidation("required") : "";
                break;
            }

            // Display or hide error based on validation result
            if (errorMessage !== "") {
              showError(field, errorMessage);
              event.preventDefault(); // Prevent form submission if any field has an error
            } else {
              hideError(field);
            }
          });
          $.ajaxSetup({
            headers: {
              "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
          });
          var $form = $(this);
          var url = $form.attr("action");
          var formData = $form.serialize();

          var formDataArray = {};
          formData.split("&").forEach(function (pair) {
            pair = pair.split("=");
            formDataArray[pair[0]] = decodeURIComponent(pair[1] || "");
          });

          function ajaxCallback(result) {
            if (!result || Object.keys(result).length === 0) {
              console.error("Result kosong atau tidak valid.");
              return;
            }

            var sendData = {
              formData: formDataArray,
              result: result,
            };

            $.ajax({
              url: $form.attr("action"),
              method: "POST",
              data: sendData,
              beforeSend: function () {
                // $("#loading-image").show();
              },
              success: function (response) {
                // alert(JSON.stringify(response.message));
                // console.log("RESPONSE DATA >>>>>", response);
                $("#loading-image").hide();
                var html = "";
                if (response.response === true) {
                  // alert('success');
                  $(".alert-danger").hide();
                  $('[data-component="check-ongkir-page"]')
                    .find(".status-table")
                    .show();
                  var header = response.result.header;
                  html +=
                    '<div class="hidden flex-wrap status-table__header lg:flex">';
                  $.each(header, function (index, name) {
                    html += "<div><h6>" + name + "</h6></div>";
                  });
                  html += "</div>";
                  html +=
                    '<div class="status-table__content">' +
                    '<div class="status-table__form">';

                  html +=
                    '<div class="relative status-table__radio">' +
                    '<div class="block flex-wrap status-table__radio-item lg:flex">' +
                    "<div>";
                  html +=
                    '<div class="block title lg:hidden">' +
                    "<h6>" +
                    header[0] +
                    "</h6>" +
                    "</div>" +
                    "<p>" +
                    response.result.data.productTypeName +
                    "</p>" +
                    "</div>" +
                    "<div>" +
                    '<div class="block title lg:hidden">' +
                    "<h6>" +
                    header[1] +
                    "</h6>" +
                    "</div>" +
                    "<p>" +
                    response.result.data.settValue +
                    "</p>" +
                    "</div>" +
                    "<div>" +
                    '<div class="block title lg:hidden">' +
                    "<h6>" +
                    header[2] +
                    "</h6>" +
                    "</div>" +
                    "<p>" +
                    response.result.data.estimateTime +
                    " HARI</p>" +
                    "</div>" +
                    "<div>" +
                    '<div class="block title lg:hidden">' +
                    "<h6>" +
                    header[3] +
                    "</h6>" +
                    "</div>" +
                    "<p>IDR " +
                    response.result.data.freightBeforeDiscount +
                    "</p>" +
                    "</div>" +
                    "<div>" +
                    '<div class="block title lg:hidden">' +
                    "<h6>" +
                    header[4] +
                    "</h6>" +
                    "</div>" +
                    "<p></p>" +
                    "</div>" +
                    "<div>" +
                    '<div class="block title lg:hidden">' +
                    "<h6>" +
                    header[5] +
                    "</h6>" +
                    "</div>" +
                    "<p>*" +
                    response.result.data.calCostDesc +
                    "</p>" +
                    "</div>" +
                    "</div>" +
                    "</div>";
                  html += "</div></div>";
                  $('[data-component="check-ongkir-page"]')
                    .find("#display-table")
                    .html(html);
                } else {
                  // console.log("GAGAL RESPONSE >>>>", response);
                  $("#loading-image").hide();
                  alert(response.message);
                }
              },
            });
          }

          requestCaptcha(ajaxCallback);
        }
      );

      function requestCaptcha(ajaxCallback) {
        try {
          var captcha = new TencentCaptcha(
            "2031495304",
            function (res) {
              callback(res, ajaxCallback);
            },
            {
              enableDarkMode: "force",
              loading: true,
              userLanguage: "id",
            }
          );
          captcha.show();
        } catch (error) {
          loadErrorCallback();
        }
      }

      $("select[name='paymentMethod']").on("change", function (e) {
        e.preventDefault();
        showShippingMethod();
      });
      $("select#dropdown-methode-pengiriman").change(function (e) {
        e.preventDefault();
        showShippingMethod();
      });

      function tambahNilaiDropdown(nilai, teks) {
        $("select#dropdown-methode-pengiriman").append(
          '<option value="' + nilai + '">' + teks + "</option>"
        );
      }

      function showShippingMethod() {
        var startCity = $deliverySelectize[0].selectize.getValue();
        var endCity = $destinationSelectize[0].selectize.getValue();
        if (startCity !== "" && endCity !== "") {
          var payment_url = $("input[name='payment-method-url']").val();
          $.ajaxSetup({
            headers: {
              "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
          });
          $.ajax({
            url: payment_url,
            method: "POST",
            data: {
              dispatchCity: startCity,
              sendCity: endCity,
            },
            success: function (result) {
              $("select#dropdown-methode-pengiriman").empty();
              var selectedValue = $("select#methode-pengiriman").val();
              if (result.succ) {
                if (result.data != "") {
                  $.each(result.data, function (i, data) {
                    tambahNilaiDropdown(
                      data.productTypeCode,
                      data.productTypeName
                    );
                  });
                  $(
                    "select#dropdown-methode-pengiriman + small.invalid-feedback"
                  ).remove();
                } else {
                  if (
                    $(
                      "select#dropdown-methode-pengiriman + small.invalid-feedback"
                    ).length === 0
                  ) {
                    $("select#dropdown-methode-pengiriman").after(
                      '<small class="invalid-feedback">Data not found</small>'
                    );
                  }
                }
                // Atur kembali nilai yang dipilih
                $("select#methode-pengiriman").val(selectedValue);
              } else {
                alert(result.msg);
              }
            },
            error: function (xhr, status, error) {
              console.error("Terjadi kesalahan: " + error);
            },
          }).done(function () {
            jQuery.ready();
          });
        } else {
          alert(
            "Silahkan pilih Lokasi Pengiriman dan Lokasi Tujuan terlebih dahulu"
          );
        }
      }
    });
  });
}
/**
 *  @page /page-services
 */
if (
  document.querySelectorAll('[data-component="services-template-page"]')
    .length > 0
) {
  document.addEventListener("DOMContentLoaded", function () {
    $(function () {
      $("#form_check_pricing").on("submit", function () {
        let origin = $.trim($("#select-origin").val()).length;
        let destination = $.trim($("#select-destination").val()).length;
        if (origin === 0) {
          $("#select-origin").prop("required", true);
          return false;
        }
        if (destination === 0) {
          $("#select-destination").prop("required", true);
          return false;
        }
      });
      $("#form_outlet_checked").on("submit", function () {
        if ($.trim($("#select-outlet").val()).length === 0) {
          $("#select-outlet").prop("required", true);
          return false;
        }
      });
      var validationMessages = {
        required: $("#error_field_required").attr("data-field_required"),
        number: $("#error_field_numeric").attr("data-field_numeric"),
      };
      var check_price_services = $("[data-form='check-price-services']");
      var check_outlet_services = $("[data-form='check-outlet-services']");
      var outlet = $("input[name='outlet']");
      check_outlet_services.find(outlet).on("input", function (e) {
        e.preventDefault();
        checkStatus($(this));
      });
      check_outlet_services.on(
        "click",
        "[data-btn='check-outlet-services']",
        function (e) {
          e.preventDefault();
          var fieldName = outlet.attr("name");
          var errorMessage = "";
          if (outlet.val() === "") {
            errorMessage = transValidation("required");
          }
          if (errorMessage !== "") {
            var $form = $(this).closest(check_outlet_services);
            $form.find(".selectize-input.items.not-full").addClass("invalid");
            outlet.next(".error-message").text(errorMessage);
            return false;
          } else {
            hideError(outlet);
            check_outlet_services.trigger("submit");
          }
        }
      );
      check_price_services
        .find("input, select")
        .on("input change", function (e) {
          e.preventDefault();
          var selectizeInput = $(".form-custom-selectize .selectize-input");
          var field = $(this);
          var fieldName = field.attr("name");
          var errorMessage = "";
          switch (fieldName) {
            case "origin":
              errorMessage =
                field.val() === "" ? transValidation("required") : "";
              break;
            case "destination":
              errorMessage =
                field.val() === "" ? transValidation("required") : "";
              break;
            case "weight":
              errorMessage =
                isNaN(field.val()) || field.val() === ""
                  ? transValidation("number")
                  : "";
              break;
          }

          if (errorMessage !== "") {
            var $form = $(this).closest(check_price_services);
            $form.find(".selectize-input.items.not-full").addClass("invalid");

            showError(field, errorMessage);
          } else {
            hideError(field);
          }
        });
      check_price_services.on(
        "click",
        "[data-btn='check-price-services']",
        function (e) {
          e.preventDefault();
          var hasError = false;
          var errorMessage = "";
          check_price_services.find("input").each(function () {
            var field = $(this);
            var fieldName = field.attr("name");
            if (typeof $(this).attr("name") != "undefined") {
              switch (fieldName) {
                case "origin":
                case "destination":
                  errorMessage =
                    field.val() === "" ? transValidation("required") : "";
                  break;
                case "weight":
                  errorMessage =
                    isNaN(field.val()) || field.val() === ""
                      ? transValidation("required")
                      : "";
                  break;
              }
            }
            if (errorMessage !== "") {
              var $form = $(this).closest(check_price_services);
              $form.find(".selectize-input.items.not-full").addClass("invalid");

              showError(field, errorMessage);
              hasError = true;
            } else {
              hideError(field);
            }
          });
          if (!hasError) {
            check_price_services.submit();
          } else {
            console.log(
              "Form has errors. Please correct them before submitting."
            );
          }
        }
      );

      function checkStatus(field) {
        var fieldName = field.attr("name");
        var errorMessage = "";
        if (field.val() === "") {
          errorMessage = transValidation("required");
        }
        if (errorMessage !== "") {
          showError(field, errorMessage);
        } else {
          hideError(field);
        }
      }

      function showError(field, message) {
        field.addClass("invalid");
        field.next(".error-message").text(message);
      }

      function hideError(field) {
        field.removeClass("invalid");
        field.next(".error-message").text("");
      }

      function transValidation(key) {
        return validationMessages[key];
      }
    });
  });
}
if (document.querySelectorAll('[data-component="statuscheck"]').length > 0) {
  document.addEventListener("livewire:load", function () {
    const radioButtons = document.querySelectorAll(
      '[data-toggle="statusradio"]'
    );
    const awbNumberInput = document.getElementById("awbNumber");
    const orderNumberInput = document.getElementById("orderNumber");

    function toggleInputs() {
      if (document.getElementById("statusName1").checked) {
        awbNumberInput.classList.remove("hidden");
        orderNumberInput.classList.add("hidden");
        awbNumberInput.setAttribute("required", "required");
        orderNumberInput.removeAttribute("required");
      } else if (document.getElementById("statusName2").checked) {
        awbNumberInput.classList.add("hidden");
        orderNumberInput.classList.remove("hidden");
        orderNumberInput.setAttribute("required", "required");
        awbNumberInput.removeAttribute("required");
      }
    }

    radioButtons.forEach((radio) => {
      radio.addEventListener("change", toggleInputs);
    });

    toggleInputs();

    function formatInput(inputElement) {
      inputElement.addEventListener("paste", function (event) {
        event.preventDefault();
        const fieldName = inputElement.getAttribute("name");
        const pasteData = (event.clipboardData || window.clipboardData).getData(
          "text"
        );
        const currentValue = event.target.value;
        let newValue = currentValue;
        if (currentValue && !currentValue.endsWith(",")) {
          newValue += ",";
        }
        newValue += pasteData.replace(/\s+/g, ",");
        event.target.value = newValue;
        // console.log('Handling paste...', newValue);
        Livewire.emit("formatOnPaste", newValue);
      });
      inputElement.addEventListener("input", function (event) {
        const fieldName = inputElement.getAttribute("name");
        const currentValue = event.target.value.replace(/\s+/g, ",");
        event.target.value = currentValue;
        // console.log('Handling input...', currentValue);
        Livewire.emit("formatOnPaste", fieldName, currentValue);
      });
    }

    formatInput(awbNumberInput);
    formatInput(orderNumberInput);
  });
}
