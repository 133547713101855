import Splide from "@splidejs/splide";
import "@splidejs/splide/css/core";

if (document.querySelectorAll('[data-component="carousel"]').length > 0) {
  document.addEventListener("DOMContentLoaded", function () {
    new Splide(".splide").mount();

    new Splide("#splideMainTestimonial", {
      type: "loop",
      autoplay: true,
      rewind: true,
      pagination: false,
      arrows: false,
      gap: 16,
      start: 3,
    })
      .sync(
        new Splide("#splideThumbnailTestimonial", {
          type: "loop",
          autoplay: true,
          fixedHeight: 100,
          gap: 16,
          rewind: true,
          pagination: false,
          isNavigation: true,
          focus: "center",
          perPage: 7,
          start: 3,
          breakpoints: {
            1024: {
              perPage: 3,
            },
            576: {
              perPage: 2,
            },
          },
        }).mount()
      )
      .mount();

    new Splide("#splideService", {
      autoplay: true,
      rewind: true,
      gap: "24px",
      arrows: false,
      pagination: false,
      perPage: 3,
      breakpoints: {
        576: {
          perPage: 1,
          gap: "8px",
        },
      },
    }).mount();

    new Splide("#splideCoverage", {
      autoplay: true,
      rewind: true,
      gap: "24px",
      arrows: false,
      pagination: false,
      perPage: 3,
      breakpoints: {
        576: {
          perPage: 1,
          gap: "8px",
        },
      },
    }).mount();

    new Splide("#splideSla", {
      autoplay: true,
      rewind: true,
      gap: "24px",
      arrows: false,
      pagination: false,
      perPage: 3,
      breakpoints: {
        576: {
          perPage: 1,
          gap: "8px",
        },
      },
    }).mount();
  });
}
