import jquery from "jquery";
window.$ = window.jQuery = jquery;

import "lazysizes";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

Fancybox.bind("[data-fancybox]");

import "./interactive/Selectize";
import "./interactive/ScrollSpy";
import "./interactive/Modal";
// import "./interactive/MapsLocator";
import "./interactive/Carousel";
import "./interactive/TCaptcha";
import "./interactive/Custom";
